export class DateHelper {
  checkAge(ageString: string): boolean {
    const currentYear = new Date().getFullYear();
    const proposed = new Date(ageString);
    return proposed.getFullYear() <= currentYear - 16;
  }

  getDays(): string[] {
    return this.calculateDuration(31).map((d) =>
      d < 10 ? `0${d}` : String(d)
    );
  }

  getMonths(): Record<string, string>[] {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return months.map((month, i) => {
      const key = i + 1;
      return {
        name: month,
        code: key > 9 ? String(key) : `0${key}`,
      };
    });
  }
  getYears(): number[] {
    const startYear = 1950;
    const current = new Date();
    const currentYear = current.getFullYear();
    return this.calculateDuration(currentYear, startYear).reverse();
  }

  private calculateDuration(end: number, start = 1): number[] {
    const range = [];
    for (let i = start; i <= end; i += 1) {
      range.push(i);
    }

    return range;
  }
}
