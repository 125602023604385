import { DateHelper } from "@/helpers/date";
import { emailUnique } from "@/helpers/registration";
import { and, email, helpers, minLength, numeric, required, requiredIf } from "@vuelidate/validators";
import { Ref, computed, ref, reactive } from "vue";

export function useGroupValidation(isAuthenticated: Ref<boolean>) {

    const showExtra = ref(false);

    const ageGate = (_: any, _siblings: any, component: any) => {
        return !component.v$.birthdate.$error;
    };
    const checkDuplicate = helpers.withAsync(
        emailUnique(isAuthenticated)
    );

    const calendarHelper = new DateHelper();
    const rules = computed(() => {
        return {
          day: { rules: and(required, ageGate) },
          month: { rules: and(required, ageGate) },
          year: { rules: and(required, ageGate) },
          club: { required },
          tasks: {
            required,
          },
          motivations: {
            required,
            minLength: minLength(1),
          },
          size: { required },
          extra: { required: requiredIf(showExtra.value) },
          postcodeInput: {
            required,
          },
          firstname: { required },
          lastname: { required },
          emailInput: {
            required: requiredIf(computed(() => !isAuthenticated.value).value),
            email: email,
            unique: checkDuplicate,
          },
          tel: {
            required,
            numeric,
          },
          birthdate: {
            required,
            checkAge: calendarHelper.checkAge,
          },
          country: { required },
          language: { required },
          privacy: { required },
          responsible: { required },
          currentLocale: { required },
          phonePrefix: { required },
          password: {
            required: requiredIf(
              computed(() => {
                return !isAuthenticated.value;
              }).value
            ),
          },
          terms: {
            required,
          },
        };
      });

    const state = reactive({
        club: "",
        size: "",
        birthdate: "",
        responsible: "me",
        currentLocale: "be",
        extra: "",
        postcodeInput: "",
        firstname: "",
        lastname: "",
        motivations: [] as string[],
        password: "",
        emailInput: "",
        phonePrefix: "+32",
        tel: "",
        day: 0,
        month: 0,
        year: 0,
        country: "",
        language: "",
        privacy: false,
        terms: false,
      });
    
    return {
        showExtra,
        rules,
        state,
    };
}