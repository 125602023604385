import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vp-input-container" }
const _hoisted_2 = { class: "p-float-label" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = {
  class: "pl-2 ml-2 mt-0",
  style: {"line-height":"1.5"}
}
const _hoisted_5 = {
  key: 0,
  class: "vp-notice p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Password = _resolveComponent("Password")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Password, {
        id: "password",
        autocomplete: "current-password",
        modelValue: _ctx.inputVal.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal.$model) = $event)),
        class: _normalizeClass({ 'p-invalid': _ctx.inputVal.$error }),
        toggleMask: "",
        weakLabel: _ctx.$t('forms.password.error.weak'),
        mediumLabel: _ctx.$t('forms.password.error.strong'),
        strongLabel: _ctx.$t('forms.password.error.strong')
      }, {
        header: _withCtx(() => [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t(`forms.password.panel.heading`)), 1)
        ]),
        footer: _withCtx((sp) => [
          _createTextVNode(_toDisplayString(sp.level) + " ", 1),
          _createVNode(_component_Divider),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(`forms.password.subheading`)), 1),
          _createElementVNode("ul", _hoisted_4, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t(`forms.password.requirement-1`)), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t(`forms.password.requirement-2`)), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t(`forms.password.requirement-3`)), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t(`forms.password.requirement-4`)), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "class", "weakLabel", "mediumLabel", "strongLabel"]),
      _createElementVNode("label", {
        for: "password",
        class: _normalizeClass({ 'p-error': _ctx.inputVal.$error })
      }, _toDisplayString(_ctx.$t(`general.password`)) + "*", 3)
    ]),
    (_ctx.inputVal.required.$invalid)
      ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t("forms.password.error.required")), 1))
      : _createCommentVNode("", true)
  ]))
}