export const getFromVueEnv = (name: string): string => {
  const key = `VUE_APP_${name.toUpperCase()}`;
  const item = process.env[key];
  if (!item) {
    console.warn(`Environment variable ${key} doesn't exist`);
    return "";
  }
  return item;
};

interface Consent {
  type: string;
  url: string;
  version: string;
}
type ConsentTypes = "terms" | "privacy";

export const getConsents = (
  types: ConsentTypes[],
  locale: string
): Consent[] => {
  const urls = types.map((type) => getFromVueEnv(`${type}_${locale}`));
  const versions = types.map((type) => getFromVueEnv(`${type}_version`));

  return types
    .filter((_, i) => Boolean(urls[i] && versions[i]))
    .map((type, i) => {
      return {
        type,
        version: versions[i],
        url: urls[i],
      };
    });
};

export type Prefix = { code: string; key: string };
export const getCurrentValue = (list: Prefix[], locale: string): Prefix => {
  const result = list.filter(
    (prefix) => prefix.key.toLowerCase() === locale.toLowerCase()
  );
  if (!result.length) {
    return list[0];
  }

  return result[0];
};
