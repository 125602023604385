
import { defineComponent, ref } from "vue";
import Password from "primevue/password";
import Divider from "primevue/divider";

export default defineComponent({
  name: "APassword",
  components: {
    Divider,
    Password,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateValue(e: Event) {
      this.$emit("update:modelValue", e.target);
    },
  },
  setup: (props) => {
    const inputVal = ref(props.modelValue);
    return {
      ...props,
      inputVal,
    };
  },
});
