
import { defineComponent, ref } from "vue";
import InputText from "primevue/inputtext";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "AInput",
  components: {
    InputText,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    showError() {
      if (!this.inputVal.$error || !this.inputVal.$errors?.[0]) {
        return "";
      }
      return this.$t(
        `forms.${this.name}.error.${this.inputVal.$errors[0].$validator}`
      );
    },
    updateValue(e: Event) {
      this.$emit("update:modelValue", e.target);
    },
  },
  setup: (props) => {
    const { t, te } = useI18n();
    const info = t(`forms.${props.name}.info`);
    const inputVal = ref(props.modelValue);

    return {
      info,
      inputVal,
      showInfo: te(`forms.${props.name}.info`),
    };
  },
});
