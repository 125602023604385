
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { computed, defineComponent, ref, reactive, watch } from "vue";
import ADivider from "@/components/atoms/a-divider.vue";
import AInput from "@/components/atoms/a-input.vue";
import useVuelidate from "@vuelidate/core";
import { toastSettings } from "@/helpers/registration";
import { SUPPORTED_LOCALES } from "@/plugins/i18n/config";
import { useI18n } from "vue-i18n";
import LetsMove from "@/assets/letsmove-logo.svg";
import { DateHelper } from "@/helpers/date";
import { loginRequestWithLocale } from "@/plugins/msal/config";
import AHgroup from "@/components/atoms/a-hgroup.vue";
import APassword from "@/components/atoms/a-password.vue";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import { useMsal } from "@/composition-api/useMsal";
import { saveRegistration } from "@/services/user.service";
import { REGISTRATION_PATHS, ROUTE_PATHS } from "@/router/config";
import { getConsents, getFromVueEnv } from "@/helpers/misc";
import { User } from "@/models/user";
import { useGroupValidation } from "@/composition-api/useGroupValidation";

const calenderHelper = new DateHelper();

export default defineComponent({
  components: {
    ADivider,
    AInput,
    AHgroup,
    APassword,
  },
  methods: {
    login(): void {
      this.instance.loginRedirect(loginRequestWithLocale(this.$i18n.locale));
    },
    showAgeError() {
      return (
        this.v$.birthdate.$error &&
        this.v$.birthdate.$errors[0].$validator === "checkAge"
      );
    },
    navigateToPrivacy() {
      const link = getFromVueEnv(`privacy_${this.$i18n.locale}`);
      if (!link) {
        return;
      }
      window.open(link, "_blank");
    },
    navigateToTerms() {
      const link = getFromVueEnv(`terms_${this.$i18n.locale}`);
      if (!link) {
        return;
      }
      window.open(link, "_blank");
    },
    isNotUnique() {
      return Boolean(
        this.v$.emailInput.$error &&
          this.v$.emailInput.$errors?.[0]?.$validator === "unique"
      );
    },
    setBirthDate() {
      if (
        !this.v$.day.$model ||
        !this.v$.month.$model ||
        !this.v$.year.$model
      ) {
        return;
      }

      this.v$.birthdate.$model = `${this.v$.year.$model}-${this.v$.month.$model}-${this.v$.day.$model}`;
      this.v$.birthdate.$validate();
    },
    setOptions() {
      // TODO: Probably needs typing - $model is of type 'unknown'
      // @ts-ignore
      this.showExtra = this.v$.motivations.$model.includes("extra");
      // @ts-ignore
      this.showVReg = this.v$.responsible.$model === "me";
    },
    async handleSubmit() {
      if (!(await this.v$.$validate())) {
        return;
      }

      const consents = getConsents(["terms", "privacy"], this.$i18n.locale);
      const birthDate = new Date(this.v$.birthdate.$model as string)
        .toISOString()
        .substr(0, 10);
      // TODO: Probably change data structure for this as well as abstract model
      const answers = [
        {
          field: "motivations",
          fieldType: "checkbox",
          label: this.$t(`forms.motivations.heading.club`),
          value: this.v$.motivations.$model as string[],
          description: (this.v$.motivations.$model as string[]).map((key) => {
            const translation = this.$t(`forms.motivations.${key}`);
            if (key === "extra") {
              return `${translation}: ${this.v$.extra.$model}`;
            }
            return translation;
          }),
        },
        {
          field: "tasks",
          fieldType: "checkbox",
          label: this.$t(`forms.tasks.heading.organisation`),
          value: this.v$.tasks.$model as string[],
          description: (this.v$.tasks.$model as string[]).map((task) => {
            return `${this.$t(`forms.tasks.${task}.main`)} - ${this.$t(
              `forms.tasks.${task}.sub`
            )}`;
          }),
        },
      ];

      try {
        this.isProcessing = true;
        const user: Record<string, any> = {
          email: this.v$.emailInput.$model,
          firstName: this.v$.firstname.$model,
          lastName: this.v$.lastname.$model,
          phonePrefix: this.v$.phonePrefix.$model,
          phoneNumber: (this.v$.tel.$model as string).replaceAll(/^0+/g, ""),
          birthDate,
        };

        if (!this.isAuthenticated) {
          user.password = this.v$.password.$model;
        }

        const response = await saveRegistration(
          {
            language: this.v$.language.$model,
            user,
            organisation: {
              country: this.v$.country.$model,
              size: this.defaults.size.indexOf(
                this.v$.size.$model as string
              ),
              name: this.v$.club.$model,
              postalCode: this.v$.postcodeInput.$model,
            },
            remarks: this.updateRemark,
            answers,
            consents,
          },
          "organisation",
          this.isAuthenticated
        );

        if (!response) {
          throw new Error();
        }

        if (Object.keys(response).length > 0) {
          const key = Object.keys(response).shift();
          const message = key ? response[key] : "";
          throw new Error(message);
        }

        this.$router.push(
          `/${this.$i18n.locale}/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.THANKS}`
        );
      } catch (e) {
        if (e instanceof Error) {
          this.$toast.add(toastSettings(e.message));
        }
        return;
      } finally {
        this.isProcessing = false;
      }
    },
  },
  mounted() {
    this.setOptions();
  },
  setup: () => {
    const { isAuthenticated, user } = useIsAuthenticated();
    const { instance } = useMsal();
    const { t } = useI18n();
    const { rules, state, showExtra } = useGroupValidation(isAuthenticated);

    const defaults = {
      motivations: ["money", "fun", "social", "learn", "extra"],
      size: ["small", "medium", "large"],
      tasks: ["steward", "service", "welcome", "green"],
      countries: [
        {
          name: t("countries.be"),
          code: "be",
        },
        {
          name: t("countries.nl"),
          code: "nl",
        },
      ],
      days: calenderHelper.getDays(),
      months: calenderHelper.getMonths(),
      years: calenderHelper.getYears(),
      language: SUPPORTED_LOCALES.map((l) => l.toUpperCase()),
      locales: [
        {
          key: "be",
          translation: "general.countries.be",
        },
        {
          key: "nl",
          translation: "general.countries.nl",
        },
      ],
      responsible: ["me", "other"],
      phonePrefix: [
        { key: "be", code: "+32" },
        { key: "nl", code: "+31" },
      ],
      remarks: t("forms.remarks.club"),
    };
  
    const isProcessing = ref(false);
    const showVReg = ref(true);
    const updateRemark = ref("");

    const setFields = (fields: User) => {
      state.firstname = fields?.firstName ?? state.firstname;
      state.lastname = fields?.lastName ?? state.lastname;
      state.emailInput = fields?.email ?? state.emailInput;
      state.tel = fields?.phone?? state.tel;
    };

    watch(
      [isAuthenticated, user],

      ([auth, fields]) => {
        if (!auth || !fields) {
          return;
        }

        setFields(fields);
      },

      { immediate: true }
    );

    const v$ = useVuelidate(rules, state, { $lazy: true });

    return {
      user,
      logo: LetsMove,
      isAuthenticated,
      isProcessing,
      showExtra,
      showVReg,
      defaults,
      updateRemark,
      v$,
      instance,
    };
  },
});
